import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";

// Local imports
import Layout from "src/layouts/LayoutWhiteHeader";
import StripPrimary from "src/components/generic/StripPrimary/StripPrimary";
import StripTitle from "src/components/generic/StripTitle/StripTitle";
import StripsAlternatingContent from "src/components/generic/StripsAlternatingContent/StripsAlternatingContent";
import SEO from "src/components/generic/SEO/SEO";
import config from "data/SiteConfig";
import Tabs from "src/components/generic/Tabs/Tabs";

// Import videos
import video1 from "static/images/pages/homepage/search_console_changing_source.mp4";
import speedVid from "static/images/pages/dashboard_comparison_sped_up.mp4";

// Styles
// import styles from "./index.module.scss";

const stripPrimary = {
	title: "Get someone else to handle your SEO data.",
	subTitle: (
		<div>
			<p>
				As sites and businesses get more complex, SEO data also goes
				from being simple to complex.
			</p>
			<p>
				Getting resource internally from BI can be impossible and
				finding the talent to hire is difficult.
			</p>
			<p>
				Piped Out provides a managed service for handling all of your
				SEO data.
			</p>
		</div>
	)
};
const largeWebsitesAlternating = [
	{
		subTitle: "A large websites means a lot of data.",
		content: (
			<React.Fragment>
				<p>
					Spreadsheets slow down, you hit row limits, sampling becomes
					more common.
				</p>
				<p>
					By pulling all your data into a warehouse, we&apos;ll let
					you work with data at any scale and you&apos;ll be doing it
					faster because all our pipelines will have done the heavy
					lifting in advance.
				</p>
				<p>
					The more data you have the more valuable you&apos;ll find
					Piped Out.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "Can you easily investigate your logs?",
		content: (
			<React.Fragment>
				<p>
					The bigger the site, the more attention you need to pay to
					crawling and indexing and log files are one of the most
					useful data sources for fixing these issues.
				</p>
				<p>
					But on big websites, logs get correspondingly large and
					require dev work to make them accessible.
				</p>
				<p>
					We work with your dev team to get logs accessible and easy
					to work with.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "Historical data anomalies",
		content: (
			<React.Fragment>
				<p>
					Big websites have complex histories and siloed data sources.
				</p>
				<p>
					We&apos;ll help you pull them all together and unify them.
				</p>
				<p>
					Data from old migrations, reconciling analytics profiles
					from your CMS & ecommerce platform. We&apos;ll help you join
					those dots together.
				</p>
			</React.Fragment>
		),
		image: null
	}
];

const lotsOfWebsitesAlternating = [
	{
		subTitle: "Lots of segmented data sources",
		content: (
			<React.Fragment>
				<p>
					The problem here is obvious. Each website you work with has
					its own set of data.
				</p>
				<p>
					If you work with 5 websites, you have 5x the number of data
					sources all of which need to be brought together.
				</p>
				<p>
					We&apos;ll do it all for you, set-up reporting and make
					switching between websites for ad-hoc analysis as easy as
					changing a drop down in a form.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "Repeating analysis",
		content: (
			<React.Fragment>
				<p>
					If you have multiple websites the other thing you&apos;re
					familiar with is reptition.
				</p>
				<p>
					Different teams need their own sets of numbers, reports and
					analysis have to be repeated over and over again.
				</p>
				<p>
					We&apos;re set-up to make reptition quick and easy and save
					you countless hours.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "One unified view",
		content: (
			<React.Fragment>
				<p>
					We build everything in a way that allows to aggregate across
					websites as well.
				</p>
				<p>
					If you need to build single reports or dashboards to
					manage/view a portfolio we&apos;re set-up to make that easy
					as well.
				</p>
			</React.Fragment>
		),
		image: null
	}
];

const reportingAlternating = [
	{
		subTitle: "Explore some of our reporting templates.",
		content: (
			<React.Fragment>
				<p>
					We&apos;ve anonymised the data for some reports so we can
					share them publically.
				</p>
			</React.Fragment>
		),
		secondContent: (
			<React.Fragment>
				<div className="reportLink">
					<h3 className="is-size-5">
						<a
							href="https://lookerstudio.google.com/u/0/reporting/6848ee68-c73c-40a5-99bb-90da18fc26d5/"
							target="blank"
							rel="noopener noreferrer"
						>
							Branded SERP Monitoring
						</a>
					</h3>
					<span>Monitor and control your brand image on Google.</span>
				</div>
				<div className="reportLink">
					<h3 className="is-size-5">
						<a
							href="https://lookerstudio.google.com/u/0/reporting/a2c4a21e-26a3-4cea-9348-d9d9d94c6535/"
							target="blank"
							rel="noopener noreferrer"
						>
							Rank Tracking
						</a>
					</h3>
					<span>Analyse rich rank tracking data.</span>
				</div>
				<div className="reportLink">
					<h3 className="is-size-5">
						<a
							href="https://lookerstudio.google.com/u/0/reporting/322d75af-be9f-48c7-9584-352d90d14dac/"
							target="blank"
							rel="noopener noreferrer"
						>
							Analytics Performance
						</a>
					</h3>
					<span>
						A base template for measuring SEO performance with
						analytics.
					</span>
				</div>
				<div className="reportLink">
					<h3 className="is-size-5">
						<a
							href="https://lookerstudio.google.com/u/0/reporting/cc80e9af-db4b-41e0-98a7-8ee29ab5d537/"
							target="blank"
							rel="noopener noreferrer"
						>
							Log Monitoring
						</a>
					</h3>
					<span>Monitor your logs for technical issues.</span>
				</div>
				<div className="reportLink">
					<h3 className="is-size-5">
						<a
							href="https://lookerstudio.google.com/u/0/reporting/88c1fbe6-9866-45d6-bbfd-23facace4474/"
							target="blank"
							rel="noopener noreferrer"
						>
							Core Web Vitals
						</a>
					</h3>
					<span>Track your domain and competitors CWV.</span>
				</div>
			</React.Fragment>
		)
	},
	{
		subTitle: "Fast and insightful dashboards",
		content: (
			<React.Fragment>
				<p>
					As data scales, dashboards slow down. We pre-build our data
					pipelines to allow your reports to run faster while still
					being insightful.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "Connecting with popular reporting platforms",
		content: (
			<React.Fragment>
				<p>
					We (primarily) use BigQuery for building the data
					warehouses.
				</p>
				<p>
					It connects with all of the popular reporting platforms,
					Data Studio, PowerBI, Tableau etc.
				</p>
				<p>
					We build our templates in Looker (Data) Studio, but all the
					business logic is in the underlying tables, so you can
					recreate them in any BI tool of choice.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		subTitle: "We've already got pre-built queries you can use",
		content: (
			<React.Fragment>
				<p>
					Want to see year on year traffic for a portfolio of sites
					split by brand and non-brand? We&apos;ve got a query for
					that.
				</p>
				<p>
					Want to report on traffic and crawling across a migration?
					We&apos;ve got a query for that.
				</p>
				<p>
					Want to see traffic over time for a specific list of pages,
					or each template on your websites? We&apos;ve got a query
					for that.
				</p>
				<p>If we don&apos;t have it, we&apos;ll make it for you.</p>
			</React.Fragment>
		),
		image: null
	}
];

const alternatingContent = [
	{
		subTitle: "What does this include?",
		content: (
			<React.Fragment>
				<ul>
					<li>
						<strong>All the data: </strong>You&apos;ll get easy,
						quick access to your data without limits.
					</li>
					<li>
						<strong>Best in class reporting: </strong>You&apos;ll
						get get access to pre-built report templates to help you
						with your reporting and common SEO tasks.
					</li>
					<li>
						<strong>Deep expertise: </strong>You&apos;ll get access
						to our deep experience setting up your data. We know the
						right dimensions to load in UA & GA4, the quirks around
						working with Search Console, what you need from rank
						tracking data.
					</li>
					<li>
						<strong>Help & support: </strong>You&apos;ll have
						someone to turn to when you do have data problems.
					</li>
				</ul>

				<p>
					Our customers get access to better analysis than they&apos;d
					be able to do themselves.
				</p>
				<p>
					And they save time & money, by letting someone else take
					care of the data issues.
				</p>
			</React.Fragment>
		),
		image: null
	}
];

const propTypes = {
	data: PropTypes.shape().isRequired,
	location: PropTypes.shape().isRequired
};

const Index = ({ data, location }) => {
	// Content for main
	alternatingContent[0].image = data.dataAllInOne.childImageSharp.fluid;

	// Set images
	largeWebsitesAlternating[0].image =
		data.largeWebsites0.childImageSharp.fluid;
	largeWebsitesAlternating[1].image =
		data.largeWebsites1.childImageSharp.fluid;
	largeWebsitesAlternating[2].image =
		data.largeWebsites2.childImageSharp.fluid;

	//  Lots of websites
	lotsOfWebsitesAlternating[0].image =
		data.differentProperties.childImageSharp.fluid;
	lotsOfWebsitesAlternating[1].video = video1;
	lotsOfWebsitesAlternating[2].image =
		data.lotsOfWebsites2.childImageSharp.fluid;

	// Reporting
	reportingAlternating[0].image = data.reporting0.childImageSharp.fluid;
	reportingAlternating[1].video = speedVid;
	reportingAlternating[2].image = data.reporting1.childImageSharp.fluid;
	reportingAlternating[3].image = data.reporting2.childImageSharp.fluid;

	// Set-up tab content.
	const largeWebsiteStrips = (
		<StripsAlternatingContent stripList={largeWebsitesAlternating} />
	);
	const lotsOfWebsitesStrips = (
		<StripsAlternatingContent stripList={lotsOfWebsitesAlternating} />
	);
	const reportingStrips = (
		<StripsAlternatingContent stripList={reportingAlternating} />
	);
	const tabStripUseCases = [
		{
			id: "largeWebsites",
			header: "Large websites",
			content: largeWebsiteStrips
		},
		{
			id: "lotsOfWebsites",
			header: "Lots of websites",
			content: lotsOfWebsitesStrips
		},
		{
			id: "reporting",
			header: "Reporting",
			content: reportingStrips
		}
	];

	const title = "Where is Piped Out useful";
	const description =
		"Now the data is loaded, we&apos;ve got huge amounts of data at our fingertips. We need working with it to be quick and painless, so we can get to problem solving and doing our jobs.";

	return (
		<Layout>
			<Helmet title={config.siteTitle} />
			<SEO
				pageTitle={title}
				pageDescription={description}
				location={location}
			/>
			<StripPrimary
				title={stripPrimary.title}
				subTitle={stripPrimary.subTitle}
				fullSized={true}
				isWhite={true}
				demoButton={true}
				reportingButton={true}
			/>
			<section>
				{/* <StripTitle title="How do we load data?" /> */}
				<StripsAlternatingContent
					stripList={alternatingContent}
					classes="m-t-xxl"
					noBotMargin={true}
					largeTitle={true}
				/>
			</section>
			<StripTitle title="Common problems we help solve" />
			<Tabs tabData={tabStripUseCases} classes="" />
		</Layout>
	);
};

Index.propTypes = propTypes;

export default Index;

// The graphql query made here will automatically be entered as the
// prop data in data
export const pageQuery = graphql`
	query UseCases {
		# Large websites
		dataAllInOne: file(
			relativePath: { eq: "images/pages/data-diagram.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 933, maxHeight: 658) {
					...GatsbyImageSharpFluid
				}
			}
		}
		largeWebsites0: file(
			relativePath: { eq: "images/pages/use_cases/excel_slow.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 587, maxHeight: 199) {
					...GatsbyImageSharpFluid
				}
			}
		}
		largeWebsites1: file(
			relativePath: { eq: "images/pages/use_cases/example_log_query.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 555, maxHeight: 333) {
					...GatsbyImageSharpFluid
				}
			}
		}
		largeWebsites2: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/multiple_subfolders.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 431, maxHeight: 570) {
					...GatsbyImageSharpFluid
				}
			}
		}

		# Lots of websites
		differentProperties: file(
			relativePath: {
				eq: "images/pages/use_cases/different_properties.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 501, maxHeight: 393) {
					...GatsbyImageSharpFluid
				}
			}
		}
		lotsOfWebsites2: file(
			relativePath: { eq: "images/pages/use_cases/single_aggregated.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 715, maxHeight: 301) {
					...GatsbyImageSharpFluid
				}
			}
		}

		# Reporting
		reporting0: file(
			relativePath: { eq: "images/pages/use_cases/filter_logic.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 753, maxHeight: 592) {
					...GatsbyImageSharpFluid
				}
			}
		}
		reporting1: file(
			relativePath: {
				eq: "images/pages/use_cases/reporting_integrations.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 450, maxHeight: 338) {
					...GatsbyImageSharpFluid
				}
			}
		}
		reporting2: file(
			relativePath: { eq: "images/pages/use_cases/example_queries.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 560, maxHeight: 377) {
					...GatsbyImageSharpFluid
				}
			}
		}
		reporting3: file(
			relativePath: { eq: "images/pages/use_cases/data_studio_error.jpg" }
		) {
			childImageSharp {
				fluid(maxWidth: 752, maxHeight: 582) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
